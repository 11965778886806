//
// Neurodigital classes
// --------------------------------------------------
.d-block{
  display: block !important;
}
.d-none{
  display: none !important;
}
.navbar-default {
  .navbar-brand {
    padding: 5px 15px;
    img {
      height: 40px;
    }
  }
}

.alert-hide {
  display: none;
}

body.login {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eee;

  .form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-signin .form-signin-heading,
  .form-signin .checkbox {
    margin-bottom: 10px;
  }
  .form-signin .checkbox {
    font-weight: normal;
  }
  .form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

footer { margin: 0; font-size: 11px; }
footer hr { margin-bottom: 5px; }

// Margins
// -------------------------
.margin-right-5px { margin-right: 5px !important; }
.margin-bottom-5px { margin-bottom: 5px !important; }
.margin-bottom-15px { margin-bottom: 15px !important; }
.margin-top-5px { margin-top: 5px !important; }
.margin-top-15px { margin-top: 15px !important; }
.no-margin-bottom { margin-bottom: 0px !important; }
.no-margin-top { margin-top: 0px !important; }
.page-header { margin-top: 0; padding-bottom: 0; }
.panel-heading .close { margin-top: -3px; }

// Paddings
// -------------------------
.no-padding       { padding: 0px !important; }
.no-padding-left  { padding-left: 0px !important; }
.no-padding-right { padding-right: 0px !important; }

// Pagination
// -------------------------
.pagination {
  display: table;
  margin: 0 auto;
}

// Panels
// -------------------------
.panel-search { display: none; }

// Colors
// -------------------------
.color-danger { color: @brand-danger; }
.color-white { color: #FFFFFF; }

a{
  text-decoration: none !important;
}

.panel-clientes, .panel-entradas, .panel-senha {
  height: 100vh;
  border-left: none;
  border-top: none;
  border-bottom: none;
  .panel-body, .list-group {
    overflow-y: auto;
    height: ~"calc(100vh - 37px)";
    .list-group-item {
      border-left: none;
      border-right: none;
    }
  }
}

.panel-clientes {
  .panel-body {
    height: auto;
    overflow: initial;
  }
  .list-group {
    height: ~"calc(100vh - 102px)";
    .list-group-item {
      padding: 8px 8px;
      .text-muted {
        opacity: 0.35;
      }
      &.active {
        a.btn-open-entradas {
          color: #FFFFFF;
        }
      }
    }
  }
}

.panel-entradas {
  .panel-body {
    height: auto;
    overflow: initial;
  }
  .list-group {
    height: ~"calc(100vh - 69px)";
    .list-group-item {
      &.active {
        a.btn-load-form-entradas {
          color: #FFFFFF;
        }
      }
    }
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .panel-clientes {
    height: auto;
    .panel-body {
      height: auto;
    }
    .list-group {
      max-height: 150px;
      height: auto;
    }
  }
  .panel-entradas {
    .list-group {
      max-height: 150px;
      height: auto;
    }
  }
  .panel-entradas, .panel-senha {
    height: auto;
    border-top: 3px solid @brand-warning;
    .panel-body, .list-group {
      height: auto;
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}